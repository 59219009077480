<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: resendEmail.vue
Description: This file contains vue component which handles the resending email functionality.
-->
<template>
  <el-row type="flex" justify="center" class="resend-email-card">
    <!-- <el-col></el-col> -->
    <el-col :span="spanValue">
      <card class="resend-email generic-card">
        <template slot="title">
          <h2>{{ $t("resend_verify_link") }}</h2>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="$t(ehm__unhandledErrorMessage)"
            type="error"
            @close="ehm__handleAlertClose"
          ></el-alert>
          <er-form
            :ref="formName"
            size="small"
            @submit.native.prevent="sumbitResendEmailDetails"
            v-loading="loading"
            element-loading-background="white"
            :element-loading-text="$t('Comn_auth_loading')"
            :model="formModel"
          >
            <div class="form-field-label">
              {{ $t("resend_verification_link_label") }}
            </div>
            <er-form-item
              prop="login_id"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.login_id)"
            >
              <er-input
                class="input-box"
                v-model="formModel.login_id"
                @keyup.native.enter="sumbitResendEmailDetails"
                :placeholder="$t('enter_user_name')"
                :maxlength="userFieldLengths.user_name"
              >
                <template slot="prepend">
                  <span class="material-icons-round icon--prefix"> account_circle </span>
                </template>
              </er-input>
            </er-form-item>
          </er-form>
          <layout-toolbar class="resend-email-layout-tool-bar" justify="center">
            <er-button
              size="small"
              class="btn_back_to_login"
              @click="navigate('sign-in')"
            >
              {{ $t("Back") }} {{ $t("Comn_to") }}
              {{ $t("Harvest_log_in") }}
            </er-button>
            <er-button
              size="small"
              class="btn_verify_submit"
              @click="sumbitResendEmailDetails"
              >{{ $t("resend_verify_link") }}
            </er-button>
          </layout-toolbar>
        </template>
      </card>
    </el-col>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin.js";
import {
  erButton,
  erForm,
  erInput,
  erFormItem
} from "@/components/base/basePondlogs.js";
import card from "./card";
import { mapActions } from "vuex";
import formRulesMixin from "@/mixins/formRulesMixin";
import fieldLengths from '../../constants/fieldLengths';

export default {
  mixins: [errorHandlerMixin, authErrorKeyMapMixin, formRulesMixin],
  components: {
    erButton,
    erForm,
    erInput,
    erFormItem,
    card
  },
  data: function () {
    return {
      userFieldLengths: fieldLengths,
      formModel: { login_id: "" },
      ehm__errMessagesObject: { login_id: "" },
      loading: false,
      formName: "resendEmailForm"
    };
  },
  methods: {
    ...mapActions("auth", {
      resendVerifyEmail: "resendVerifyEmail"
    }),
    navigate(component) {
      this.$router.push("/" + component);
    },
    ehm__error403Handler: function (error) {
      const errorCodeObj = {
        ACCOUNT_IS_NOT_EXISTS: 'account_not_exists',
        PLEASE_ENTER_REGISTERED_EMAIL: 'resend_link_msg_fro_email',
      }
     this.ehm__unhandledErrorMessage = errorCodeObj[error.response.data.error_code] ? errorCodeObj[error.response.data.error_code] : 'account_not_exists'
    },
    ehm__error409Handler: function () {
        this.ehm__unhandledErrorMessage = "username_is_already_verified";
    },
    async sumbitResendEmailDetails() {
      if (this.loading) {
        return;
      }

      try {
        this.loading = true;
        this.ehm__unhandledErrorMessage = "";
        this.ehm__errMessagesObject = { login_id: "" };
        await this.resendVerifyEmail({ login_id: this.formModel.login_id });
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Usrs_link_sent_to_comm_channel"),
          duration: 5000,
          type: "success"
        });
        this.$router.push("/sign-in");
      } catch (err) {
        this.ehm__errorMessages(err, false);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.resend-email-card {
  .resend-email-layout-tool-bar {
    min-width: 300px;
  }
  .el-card {
    height: 100%;
  }
  .btn_verify_submit {
    @include button-text-size;
    background-color: #389918;
    color: white;
    max-width: 13.5rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}
</style>
